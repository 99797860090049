@tailwind base;
@tailwind components;
@tailwind utilities;

/* Calendar */

:root {
  --fc-neutral-text-color: #cccccc;
  --fc-border-color: #333333;
  --fc-today-bg-color: rgba(43, 120, 221, 0.15);
  --fc-page-bg-color: #171717;
  --fc-event-text-color: #fff;
  --fc-now-indicator-color: #f00;
  --fc-button-text-color: #fff;
  --fc-button-bg-color: #0080ff;
  --fc-button-border-color: #0080ff;
  --fc-button-hover-bg-color: #0072e4;
  --fc-button-hover-border-color: #0072e4;
  --fc-button-active-bg-color: #005ebd;
  --fc-button-active-border-color: #005ebd;
}

.fc-event-past {
  background-color: #2d6398;
}

/* Scrollbar */

body {
  overflow: overlay;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: #aaaaaa;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #888888;
}

/* Unused styles for markdown styling */

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  @apply bg-gray-850 text-gray-200 border-gray-700 !important;
}

.ql-toolbar.ql-snow {
  @apply rounded-t-lg;
}

.ql-container.ql-snow {
  @apply rounded-b-lg;
}

.ql-snow .ql-stroke {
  stroke: #ccc !important;
}

.ql-snow .ql-fill {
  stroke: #ccc !important;
  fill: #ccc !important;
}

.ql-editor p {
  @apply mb-2;
}

.btn-sm {
  @apply font-medium inline-flex items-center justify-center border border-transparent rounded leading-snug transition duration-150 ease-in-out;
}

.btn-sm {
  @apply px-4 py-2;
}

@media (max-width: 640px) {
  #newsreel article:not(:first-child) {
    display: none
  }
}