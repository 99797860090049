.divider {
  overflow: hidden;
  position: relative;
}
.divider::before {
  content: "";
  position: absolute;
  z-index: 3;
  pointer-events: none;
  background-repeat: no-repeat;
  bottom: -0.1vw;
  left: -0.1vw;
  right: -0.1vw;
  top: -0.1vw;
  transform: scaleX(1.5);
  transform-origin: 100% 0;
  animation: 15s infinite alternate shape-anim ease;
  background-size: 100% 180px;
  background-position: 50% 0%;
  background-image: url('data:image/svg+xml;charset=utf8, <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100"><g fill="%23262626"><path opacity=".5" d="M1000 50V34L276 78l724-28z"/><path opacity=".3" d="M1000 101V50L401 73l599 28z"/><path d="M1000 36V-2L0 1v100l1000-65z"/></g></svg>');
}

@media (min-width: 2100px) {
  .divider::before {
    background-size: 100% calc(2vw + 180px);
  }
}

@keyframes shape-anim {
  100% {
    transform: scaleX(1.5) translateX(calc(100% - (100% / 1.5)));
  }
}
